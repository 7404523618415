import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./mobile.css";
import { useEffect, useState } from "react";
import bg from "./bg.png";
import grwolight from "./growlight.png";
import logo from "./fizilogo.png";
import fiziicon from "./fiziicon.png";
import metamask from "./metamask.png";
import DropdownWithIcons from "./DropDownWithIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import abi from "./abi";
import busdAbi from "./busdabi";
import Web3 from "web3";
import oldabi from "./oldabi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let web3;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

if (isMobile) {
  if (typeof window.ethereum !== "undefined") {
    // MetaMask is installed
    // Open the dapp in the MetaMask browser
    window.location.href = "ethereum:" + window.location.href;
  } else {
    // MetaMask is not installed
    // Redirect the user to the MetaMask download page
    window.location.href = "https://metamask.app.link/dapp/presale.fidarzi.com";
  }
} else {
  // For non-mobile devices, you can simply check if MetaMask is installed as before
  if (typeof window.ethereum !== "undefined") {
    // MetaMask is installed
    // You can proceed with your web3 functionality
  } else {
    // MetaMask is not installed
    // Redirect the user to the MetaMask download page
    window.location.href = "https://metamask.app.link/dapp/presale.fidarzi.com";
  }
}

const CONTRACT_ABI = abi; // Your contract ABI
const CONTRACT_ADDRESS = "0xD6Db40f4EA3B664EDa16a46A14808B2F2A75C683";

function App() {
  /*==Rneder Controll===*/
  const [isLoadingOne, setIsLoadingOne] = useState(true);
  const [isLoadingTwo, setIsLoadingTwo] = useState(true);

  /*====Price Data======*/
  const [bnbPrice, setBnbPrice] = useState(613.54);
  const [busdPrice, setBusdPrice] = useState(1.019);
  const [usdtPrice, setUsdtPrice] = useState(1);
  const [wethPrice, setWethPrice] = useState(3708.74);
  const [btcbPrice, setBtcbPrice] = useState(69047);
  /*Fizi Prices in other cryptos*/
  const [fiziPriceBnb, setFiziPriceBnb] = useState();
  const [fiziPriceBusd, setFiziPriceBusd] = useState();
  const [fiziPriceBtcb, setFiziPriceBtcb] = useState();
  const [fiziPriceWeth, setFiziPriceWeth] = useState();
  const [fiziPriceUsdt, setFiziPriceUsdt] = useState();

  const [walletAddress, setWalletAddress] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fiziRate, setFiziRate] = useState();

  /*=====User balances=====================*/
  const [userBNBbalance, setUserBNBbalance] = useState();
  const [userFIZIBalance, setUserFIZIBalance] = useState("0");

  /*=============Fire Wallet Connect on start=========*/
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((accounts) => {
        if (accounts.length > 0) {
          // User is already connected
          console.log("User already connected");
          const account = accounts[0];
          setWalletAddress(account);
          networkChecker();
        } else {
          // User not connected, request access
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((accounts) => {
              // After successful connection
              if (accounts.length > 0) {
                const account = accounts[0];
                setWalletAddress(shortenAddress(account));
                networkChecker();
              }
            })
            .catch((error) => {
              console.error("User denied account access", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error checking if user is connected", error);
      });
  } else if (window.web3) {
    // Legacy dapp browsers...
    web3 = new Web3(window.web3.currentProvider);
  } else {
    console.log(
      "Non-Ethereum browser detected. You might want to consider trying MetaMask!"
    );
  }

  const addFIZItoMetamask = async () => {
    try {
      // Check if MetaMask is installed
      if (window.ethereum && window.ethereum.isMetaMask) {
        // Check if there's an account connected
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          // MetaMask is installed and an account is connected
          const OLD_CONTRACT_ADDRESS =
            "0x3cD60B512fAAeDDa07548c898e80fe818e076EeC";
          const tokenAddress = OLD_CONTRACT_ADDRESS;
          const tokenSymbol = "FIZI";
          const tokenDecimals = 18;
          const tokenImage = "http://placekitten.com/200/300"; // Token image URL

          try {
            // Attempt to add the token
            const wasAdded = await window.ethereum.request({
              method: "wallet_watchAsset",
              params: {
                type: "ERC20", // Initially only supports ERC20, but future versions may support more types
                options: {
                  address: tokenAddress, // The address of the token contract
                  symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars
                  decimals: tokenDecimals, // The number of token decimals
                  image: tokenImage, // A string url of the token logo
                },
              },
            });

            if (wasAdded) {
              console.log("FIZI token was added to MetaMask");
            } else {
              console.log(
                "Attempted to add FIZI token to MetaMask. If it was not added, it might already be there."
              );
            }
          } catch (error) {
            console.error("Error adding FIZI token to MetaMask", error);
          }
        } else {
          // MetaMask is installed but no account is connected
          alert("Please connect to MetaMask first.");
        }
      } else {
        console.log("MetaMask is not installed");
      }
    } catch (error) {
      console.error("Error checking MetaMask status:", error);
    }
  };

  async function networkChecker() {
    if (window.ethereum) {
      const chainId = await window.ethereum.request({
        method: "eth_chainId", // Get the chain ID of the connected network
      });
      if (chainId === "0x38") {
      } else {
        toast.warn("Please Switch to BNB Mainnet", {
          theme: "colored",
        });
      }
    }
  }
  /*=============FiziUnits To get=================*/
  const [fiziUnitsToGet, setFiziUnitsToGet] = useState(0);
  /*==============crypto needed for Fizi Units to get=======*/
  const [cryptoEntredToBuyFizi, setCryptoEntredToBuyFizi] = useState(0);
  /*User Buy Currency Selection*/
  const [selectedBuyCurrency, setSelectedBuyCurrency] = useState("BNB");

  /*=====Fetching Functions===============*/
  // Define the cache outside your component/function
  let coinDataCache = null;

  async function getCoinData() {
    setIsLoadingOne(true);

    // Check if we have cached data
    if (coinDataCache) {
      const {
        binanceBitcoinPrice,
        binanceBridgedUsdtBnbSmartChainPrice,
        binanceCoinPrice,
        binancePegBusdPrice,
        wethPriceFromAPI,
      } = coinDataCache;
      setBtcbPrice(binanceBitcoinPrice);
      setUsdtPrice(binanceBridgedUsdtBnbSmartChainPrice);
      setBnbPrice(binanceCoinPrice);
      setBusdPrice(binancePegBusdPrice);
      setWethPrice(wethPriceFromAPI);
      setIsLoadingOne(false);
      return; // Stop execution and use cached data
    }

    const res = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cweth%2Cbinance-bitcoin%2Cbinance-peg-busd%2Cbinance-bridged-usdt-bnb-smart-chain&vs_currencies=usd"
    );
    const data = await res.json();
    const binanceBitcoinPrice = data["binance-bitcoin"].usd;
    const binanceBridgedUsdtBnbSmartChainPrice =
      data["binance-bridged-usdt-bnb-smart-chain"].usd;
    const binanceCoinPrice = data.binancecoin.usd;
    const binancePegBusdPrice = data["binance-peg-busd"].usd;
    const wethPriceFromAPI = data.weth.usd;

    // Update state with fetched data
    setBtcbPrice(binanceBitcoinPrice);
    setUsdtPrice(binanceBridgedUsdtBnbSmartChainPrice);
    setBnbPrice(binanceCoinPrice);
    setBusdPrice(binancePegBusdPrice);
    setWethPrice(wethPriceFromAPI);

    // Update the cache with new data
    coinDataCache = {
      binanceBitcoinPrice,
      binanceBridgedUsdtBnbSmartChainPrice,
      binanceCoinPrice,
      binancePegBusdPrice,
      wethPriceFromAPI,
    };

    setIsLoadingOne(false);
  }

  async function getFiziPriceUSD() {
    const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);

    const fizipriceWei = await contract.methods.getFIZIPrice().call();
    const fiziPriceUSD = web3.utils.fromWei(fizipriceWei, "ether");
    setFiziRate(fiziPriceUSD);
    console.log(fiziPriceUSD);
  }

  /*=========Get Fizi balance of the connected user========*/
  async function fetchAndSetFIZIBalance() {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        const OLD_CONTRACT_ADDRESS =
          "0x3cD60B512fAAeDDa07548c898e80fe818e076EeC";
        await window.ethereum.request({ method: "eth_requestAccounts" }); // Request account access
        const accounts = await web3.eth.getAccounts(); // Get list of accounts
        if (accounts.length === 0) return; // Ensure there's at least one account
        const account = accounts[0]; // Use the first account

        const tokenContract = new web3.eth.Contract(
          oldabi,
          OLD_CONTRACT_ADDRESS
        );
        const balance = await tokenContract.methods.balanceOf(account).call();
        const balanceFormatted = web3.utils.fromWei(balance, "ether");

        const balanceRounded = parseFloat(balanceFormatted).toFixed(2); // Convert to float and round to 2 decimal places
        setUserFIZIBalance(balanceRounded);
      } catch (error) {
        console.error("Could not fetch the FIZI balance:", error);
      }
    } else {
      console.log("Please install MetaMask.");
    }
  }

  /*==========crypto Transfer/ deducting Functions=================*/

  const sendFIZI = async () => {
    const OLD_CONTRACT_ADDRESS = "0x3cD60B512fAAeDDa07548c898e80fe818e076EeC";
    // Initialize Web3
    const web3 = new Web3("https://bsc-dataseed.bnbchain.org");
    const recipientAddress = walletAddress;
    const amount = fiziUnitsToGet;
    // Your account and FIZI token details
    const accountFrom = {
      address: process.env.REACT_APP_W_ADD, // Your wallet address
      privateKey: process.env.REACT_APP_W_PK, // Your private key
    };
    const fiziContractAddress = OLD_CONTRACT_ADDRESS; // FIZI contract address

    const contract = new web3.eth.Contract(oldabi, OLD_CONTRACT_ADDRESS);
    const amountToSend = web3.utils.toWei(amount.toString(), "ether"); // Convert amount to Wei

    // Estimate gas
    const gas = await contract.methods
      .transfer(recipientAddress, amountToSend)
      .estimateGas({ from: accountFrom.address });
    const gasPrice = await web3.eth.getGasPrice(); // Fetch current gas price

    // Transaction object
    const tx = {
      from: accountFrom.address,
      to: fiziContractAddress,
      data: contract.methods
        .transfer(recipientAddress, amountToSend)
        .encodeABI(),
      gas,
      gasPrice,
    };

    try {
      // Sign and send transaction
      const signedTx = await web3.eth.accounts.signTransaction(
        tx,
        accountFrom.privateKey
      );
      const receipt = await web3.eth.sendSignedTransaction(
        signedTx.rawTransaction
      );
      console.log("Transaction receipt: ", receipt);
    } catch (error) {
      console.error("FIZI transfer failed: ", error);
    }
  };

  /*================buy Functions================*/

  const buyFIZI = async () => {
    //console.log(process.env.REACT_APP_W_ADD);
    if (selectedBuyCurrency === "BNB") {
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      // Amount to send
      const amountToSend = web3.utils.toWei(
        cryptoEntredToBuyFizi.toString(),
        "ether"
      ); // Convert BNB to Wei

      // Specify the transaction parameters
      const transactionParameters = {
        to: process.env.REACT_APP_Tresurey_W_ADD, // Replace with the recipient's address
        from: walletAddress,
        value: web3.utils.toHex(amountToSend), // in Wei
      };

      // Using MetaMask's request method to send the transaction
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
        console.log("Transaction Hash:", txHash);

        console.log("Funds Deducted Now transfering FIZI");
        sendFIZI();
      } catch (error) {
        console.error("Error:", error);
        alert("Transfer failed:", error.message);
      }
    }
    if (selectedBuyCurrency === "BUSD") {
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

      const busdContractAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      const contract = new web3.eth.Contract(busdAbi, busdContractAddress);

      // This is the correct way to handle the conversion; you had converted it twice before.
      const amountToSend = web3.utils.toWei(
        cryptoEntredToBuyFizi.toString(),
        "ether"
      );

      const recipientAddress = process.env.REACT_APP_Tresurey_W_ADD;

      console.log("Treasury wallet is: ", recipientAddress);

      try {
        await contract.methods
          .transfer(recipientAddress, amountToSend)
          .send({ from: walletAddress });
        console.log("Funds Deducted Now transfering FIZI");
        sendFIZI();
      } catch (error) {
        console.error("Transfer failed:", error);
        alert("Transfer failed:", error.message);
      }
    }
    if (selectedBuyCurrency === "WETH") {
      const wethContractAddress = "0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA";
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      const contract = new web3.eth.Contract(busdAbi, wethContractAddress); // ABI for ERC-20 is standard
      const amountToSend = web3.utils.toWei(
        cryptoEntredToBuyFizi.toString(),
        "ether"
      ); // Assuming WETH decimals are 18

      const recipientAddress = process.env.REACT_APP_Tresurey_W_ADD;
      console.log("Treasury wallet is: ", recipientAddress);

      try {
        await contract.methods
          .transfer(recipientAddress, amountToSend)
          .send({ from: walletAddress });
        console.log("Funds Deducted Now transfering FIZI");
        sendFIZI();
      } catch (error) {
        console.error("Transfer failed:", error);
        alert("Transfer failed:", error.message);
      }
    }

    if (selectedBuyCurrency === "BTCB") {
      const btcbContractAddress = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"; // Replace with the actual BTCB contract address
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      const contract = new web3.eth.Contract(busdAbi, btcbContractAddress); // The ABI for ERC-20 is standard
      const amountToSend = web3.utils.toWei(
        cryptoEntredToBuyFizi.toString(),
        "ether"
      ); // Assuming BTCB decimals are 18

      const recipientAddress = process.env.REACT_APP_Tresurey_W_ADD;
      console.log("Treasury wallet is: ", recipientAddress);

      try {
        await contract.methods
          .transfer(recipientAddress, amountToSend)
          .send({ from: walletAddress });
        console.log("Funds Deducted Now transfering FIZI");
        sendFIZI();
      } catch (error) {
        console.error("Transfer failed:", error);
        alert("Transfer failed:", error.message);
      }
    }

    if (selectedBuyCurrency === "USDT") {
      const usdtBscContractAddress =
        "0x55d398326f99059fF775485246999027B3197955"; // USDT (BEP-20) contract address on BSC
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      const contract = new web3.eth.Contract(busdAbi, usdtBscContractAddress); // The ABI for ERC-20/BEP-20 is standard
      const amountToSend = web3.utils.toWei(
        cryptoEntredToBuyFizi.toString(),
        "ether"
      ); // Assuming USDT decimals are 18

      const recipientAddress = process.env.REACT_APP_Tresurey_W_ADD;
      console.log("Treasury wallet is: ", recipientAddress);

      try {
        await contract.methods
          .transfer(recipientAddress, amountToSend)
          .send({ from: walletAddress });
        console.log("Funds Deducted Now transfering FIZI");
        sendFIZI();
      } catch (error) {
        console.error("Transfer failed:", error);
        alert("Transfer failed:", error.message);
      }
    }
  };

  /*==================useEffects===============*/
  useEffect(() => {
    getCoinData();
    getBNBBalance();
    fetchAndSetFIZIBalance();
    getFiziPriceUSD();
  }, []);

  useEffect(() => {
    const calculateFiziPriceInBnb = () => {
      setIsLoadingTwo(true);
      if (bnbPrice === 0) {
        console.error("BNB price is not set or is zero.");
        return;
      }

      const fiziPriceInBnb = fiziRate / bnbPrice;
      setFiziPriceBnb(fiziPriceInBnb);
      console.log("Fizi Price in BNB", fiziPriceInBnb);
      setIsLoadingTwo(false);
    };

    const calculateFiziPriceInBUSD = () => {
      if (busdPrice === 0) {
        console.error("BUSD price is not set or is zero.");
        return;
      }

      const fiziPriceInBusd = fiziRate / busdPrice;
      setFiziPriceBusd(fiziPriceInBusd);
      console.log("Fizi Price in BUSD", fiziPriceInBusd);
    };
    const calculateFiziPriceInBtcb = () => {
      if (btcbPrice === 0) {
        console.error("BTCB price is not set or is zero.");
        return;
      }

      const fiziPriceInBtcb = fiziRate / btcbPrice;
      setFiziPriceBtcb(fiziPriceInBtcb);
      console.log("Fizi Price in BTCB", fiziPriceInBtcb);
    };

    const calculateFiziPriceInUsdt = () => {
      if (usdtPrice === 0) {
        console.error("USDT price is not set or is zero.");
        return;
      }

      const fiziPriceInUsdt = fiziRate / usdtPrice;
      setFiziPriceUsdt(fiziPriceInUsdt);
      console.log("Fizi Price in USDT", fiziPriceInUsdt);
    };

    const calculateFiziPriceInWeth = () => {
      if (wethPrice === 0) {
        console.error("WETH price is not set or is zero.");
        return;
      }

      const fiziPriceInWeth = fiziRate / wethPrice;
      setFiziPriceWeth(fiziPriceInWeth);
      console.log("Fizi Price in WETH", fiziPriceInWeth);
    };
    function calcAllFiziPriceInCrypto() {
      setIsLoadingTwo(true);
      calculateFiziPriceInWeth();
      calculateFiziPriceInUsdt();
      calculateFiziPriceInBtcb();
      calculateFiziPriceInBUSD();
      calculateFiziPriceInBnb();
      setIsLoadingTwo(false);
    }
    calcAllFiziPriceInCrypto();
  }, [fiziRate, bnbPrice]);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  /*==============Handler Functions======================*/

  /*----Connect Wallet handler--------*/
  const connectWalletHandler = async () => {
    if (window.ethereum) {
      // Check if MetaMask is installed
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        }); // Request accounts
        const account = accounts[0];
        setWalletAddress(account);
        networkChecker();
      } catch (err) {
        console.error(err);
      }
    } else {
      console.log("Please install MetaMask!");
    }
  };

  function disconnectWallet() {
    setWalletAddress("");
    console.log("Wallet disconnected. Wallet address:", walletAddress);
  }
  const handleOptionSelected = (option) => {
    setCryptoEntredToBuyFizi(0);
    setFiziUnitsToGet(0);
    setSelectedBuyCurrency(option.value);
    console.log("Selected option:", option.value);
  };

  function cryptoInputChangeHandler(e) {
    setCryptoEntredToBuyFizi(e.target.value);
    if (selectedBuyCurrency === "BNB") {
      console.log("from if statement: ", selectedBuyCurrency);
      const FIZIUnitsforentredcrypto = Math.floor(
        e.target.value / fiziPriceBnb
      );
      setFiziUnitsToGet(FIZIUnitsforentredcrypto);
      console.log("You will get ", FIZIUnitsforentredcrypto);
    }
    if (selectedBuyCurrency === "BUSD") {
      console.log("from if statement: ", selectedBuyCurrency);
      const FIZIUnitsforentredcrypto = Math.floor(
        e.target.value / fiziPriceBusd
      );
      setFiziUnitsToGet(FIZIUnitsforentredcrypto);
      console.log("You will get ", FIZIUnitsforentredcrypto);
    }
    if (selectedBuyCurrency === "BTCB") {
      console.log("from if statement: ", selectedBuyCurrency);
      const FIZIUnitsforentredcrypto = Math.floor(
        e.target.value / fiziPriceBtcb
      );
      setFiziUnitsToGet(FIZIUnitsforentredcrypto);
      console.log("You will get ", FIZIUnitsforentredcrypto);
    }
    if (selectedBuyCurrency === "USDT") {
      console.log("from if statement: ", selectedBuyCurrency);
      const FIZIUnitsforentredcrypto = Math.floor(
        e.target.value / fiziPriceUsdt
      );
      setFiziUnitsToGet(FIZIUnitsforentredcrypto);
      console.log("You will get ", FIZIUnitsforentredcrypto);
    }
    if (selectedBuyCurrency === "WETH") {
      console.log("from if statement: ", selectedBuyCurrency);
      const FIZIUnitsforentredcrypto = Math.floor(
        e.target.value / fiziPriceWeth
      );
      setFiziUnitsToGet(FIZIUnitsforentredcrypto);
      console.log("You will get ", FIZIUnitsforentredcrypto);
    }
  }

  function fiziUnitsToGetChnageHandler(e) {
    const entredFizniUnitsToGet = e.target.value;
    setFiziUnitsToGet(entredFizniUnitsToGet);
    const cryptoNeededToGetSelectedFiziUnits = parseFloat(
      (entredFizniUnitsToGet * fiziPriceBnb).toFixed(10)
    );

    setCryptoEntredToBuyFizi(cryptoNeededToGetSelectedFiziUnits);
  }

  /*=============Balance Getters================*/
  async function getBNBBalance() {
    // Check if MetaMask is available
    if (window.ethereum) {
      try {
        // Request account access if needed
        await window.ethereum.request({ method: "eth_requestAccounts" });

        // We use MetaMask's provider.
        const web3 = new Web3(window.ethereum);

        // Get the user's accounts
        const accounts = await web3.eth.getAccounts();

        // Ensure we have at least one account
        if (accounts.length === 0) {
          console.log("No account found");
          return;
        }

        // Use the first account to fetch the balance
        const balanceWei = await web3.eth.getBalance(accounts[0]);

        // Convert the balance from Wei to BNB
        const balanceBNB = web3.utils.fromWei(balanceWei, "ether");
        setUserBNBbalance(balanceBNB);
        return balanceBNB;
      } catch (error) {
        console.error("Error fetching BNB balance:", error);
        return;
      }
    } else {
      console.log("MetaMask is not installed");
      return;
    }
  }

  /*==============Utility Functions=======================*/
  const shortenAddress = (address) => {
    return (
      address.substring(0, 6) + "..." + address.substring(address.length - 4)
    );
  };

  if (isLoadingOne || isLoadingTwo) {
    return (
      <div className="loading-div">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div
      className="app-container"
      style={{
        backgroundImage: `url(${grwolight})`,
        backgroundSize: "cover",
      }}
    >
      <ToastContainer />
      <img src={bg} alt="bg" className="temp" />

      <div className="row">
        <div className="navigation-bar">
          <div className="logo-div">
            <img className="logo-image" src={logo} alt="fizi logo" />
          </div>
          <div className="connect-wal-div">
            <div className="connect-wal" onClick={connectWalletHandler}>
              <p
                style={{
                  marginBottom: "0px",
                  cursor: "pointer",
                }}
              >
                {walletAddress
                  ? shortenAddress(walletAddress)
                  : "Connect Wallet"}
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: " 110px" }}>
        <div className="padded-container">
          <div className="top-section">
            <h2 className="text-center">Welcome to the FIZI Presale</h2>
            <h4>This is our FIZI token address</h4>
            <h5>
              <a href="https://bnb.com" className="smart-contract-link">
                0x3cD60B512fAAeDDa07548c898e80fe818e076EeC
              </a>
            </h5>
            <div className="modalandbutton">
              <Modal isVisible={isModalVisible} onClose={closeModal}>
                <h2 className="text-center">How to buy on Mobile App</h2>

                <p>
                  <b>Step 1:</b> Open the MetaMask mobile app
                </p>
                <p>
                  <b>Step 2:</b> Click on the "Browser" button at the bottom of
                  the screen
                </p>
                <p>
                  <b>Step 3:</b> Type in the URL of the web3 platform
                </p>
                <p>
                  <b>Step 4:</b> Click on the "Connect" button to allow access
                  to your MetaMask wallet
                </p>
                <p>
                  <b>Step 5:</b> Sign the transaction to connect your wallet to
                  the platform
                </p>
                <p>
                  <b>Step 6:</b> Confirm the transaction
                </p>
                <p>
                  You will now be connected to the web3 platform and can start
                  using its features.
                </p>
              </Modal>
              <button className="how-btn" onClick={openModal}>
                {" "}
                How To Use
              </button>
            </div>
          </div>

          <div className="transparent-div section2">
            <div>
              <p className="buy-div text-center rate">
                FIZI Price : <b>{fiziRate}</b>
              </p>
              <div>
                <div className="buy-content-div">
                  <p className="text-center buy-prompt-text">
                    Please enter the FIZI amount you'd like to purchase
                  </p>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="from-col">
                        <div className="from-text-div">
                          <p>From</p>
                          <p>Balance: {userBNBbalance}</p>
                          {/* <p>Balance:0</p> */}
                        </div>
                        <div className="from-inputs-div">
                          <input
                            type="number"
                            placeholder="0.0"
                            min={0}
                            max={15000}
                            id="crypto-input"
                            value={cryptoEntredToBuyFizi}
                            onChange={cryptoInputChangeHandler}
                            onInput={cryptoInputChangeHandler}
                            inputMode="decimal"
                            pattern="[0-9]*"
                          />

                          <DropdownWithIcons
                            onOptionSelected={handleOptionSelected}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 symbol-col arrow-div">
                      <FontAwesomeIcon className="arrow" icon={faArrowRight} />
                    </div>
                    <div className="col-md-5">
                      <div className="to-col">
                        <div className="to-text-div">
                          <p>To</p>
                          <p>Balance: {userFIZIBalance}</p>
                        </div>
                        <div className="from-inputs-div">
                          <div>
                            <input
                              type="number"
                              placeholder="0.0"
                              id="fiziunitstoget"
                              value={fiziUnitsToGet}
                              min={0}
                              onChange={fiziUnitsToGetChnageHandler}
                              onInput={fiziUnitsToGetChnageHandler}
                              inputMode="decimal"
                              pattern="[0-9]*"
                            />
                          </div>

                          <div className="to-bundle-div">
                            <img
                              src={fiziicon}
                              alt="fizi"
                              style={{ width: "30px" }}
                            />
                            <p className="bundle-div-text">FIZI</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buy-btn-div">
                    {" "}
                    <button
                      className="buy-btn"
                      onClick={() => buyFIZI()}
                      // onClick={fetchData}
                    >
                      Buy FIZI
                    </button>
                  </div>
                  <div className="add-fizi-div" onClick={addFIZItoMetamask}>
                    <img
                      src={metamask}
                      alt="metamask icon"
                      className="metamask-icon"
                    />
                    <p className="add-to-metamask-text">Add FIZI to Metamask</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
