import React, { useState } from "react";
import Select from "react-select";

// Dummy icon imports, replace these with your actual icon paths
import bnbIcon from "./bnb.png";
import busdIcon from "./busd.png";
import usdtIcon from "./usdt.png";
import ethIcon from "./eth.png";
import btcbIcon from "./btcb.png";

const DropdownWithIcons = ({ onOptionSelected }) => {
  const options = [{ value: "BNB", label: "BNB", icon: bnbIcon }];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      color: "white",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "grey",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#5c5c5c" : "#333333",
      color: "white",
      "&:hover": {
        backgroundColor: "darkgrey",
      },
    }),
  };

  const formatOptionLabel = ({ icon, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={icon} alt={label} style={{ marginRight: 10, width: 30 }} />
      {label}
    </div>
  );

  const handleChange = (option) => {
    setSelectedOption(option);
    if (onOptionSelected) {
      onOptionSelected(option);
    }
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
    />
  );
};

export default DropdownWithIcons;
